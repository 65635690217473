.loading-image-container{
    position: relative;
}

.loading-image-container .loader-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-image-container .loader-none{
    display: none;
}

.loading-image-container .loader-wrapper .loader-image{
    width: 30%;
    height: auto;
    background: none;
}


